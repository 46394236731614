@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', 'Noto Sans KR', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ================ common ================ */
main {
  padding: 0px 98px 150px;
  background-color: #F0F2F7;
  min-height: calc(100vh - 73px);
}

main h1 {
  font-size: 20px;
  padding: 26px 0px 0px;
}

main h2 {
  font-size: 18px;
  color: #363740;
  padding: 10px 0px;
}

a {
  color: unset;
  text-decoration: unset;
}

button {
  cursor: pointer;
}

button[type=submit]:disabled {
  background: unset !important;
}

table {
  cursor: default;
  padding: 0px;
  border-spacing: 0px 12px;
  box-shadow: none;
  width: 100%;
  text-align: center;
  table-layout: fixed;
  word-break: keep-all;
}

table tbody {
  position: relative;
  top: -12px;
}

table tbody tr {
  background-color: white;
  box-shadow: #0000001A 0px 1px 4px;
}

table:not(.not-hover) tbody tr:hover {
  background-color: #F8F8F8;
  color: #495EDE;
}

table:not(.not-hover) tbody tr:hover .custom-link {
  color: #007FFF;
}

table .text-left {
  text-align: left;
}

table th {
  padding: 13px 25px;
  font-size: 14px;
  color: rgba(29, 30, 44, 0.3);
}

table td {
  padding: 13px 25px;
  height: 80px;
}

td>select {
  appearance: none;
  background-color: white;
  background-image: url("/src/assets/image/common/selectArrow.svg");
  background-repeat: no-repeat;
  background-position-x: 60px;
  background-position-y: center;
  width: 80px;
  padding: 10px;
  border: 1px solid #C3CBDB;
  border-radius: 5px;
  box-shadow: #00000014 1px 2px 2px;
}

.form-labels {
  margin-top: 24px;
  padding: 24px 0px;
  background-color: white;
  box-shadow: #0000001A 0px 1px 4px;
}

.form-labels .color-red {
  font-size: 14px;
}

.form-labels label {
  display: block;
  margin: 30px 0px;
}

.form-labels .color-red+label {
  margin-top: 9px;
}

.form-labels label>span {
  display: inline-block;
  width: 235px;
  padding-left: 60px;
  font-size: 14px;
  font-weight: bold;
}

.form-labels .long {
  width: 350px;
}

.form-labels label input,
.form-labels label select,
.form-labels label textarea {
  height: 42px;
  margin-left: 30px;
  padding: 10px;
  background-color: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  color: #6E6F76;
  font-family: 'Noto Sans KR';
}

.form-labels label input,
.form-labels label select {
  width: 224px;
  padding-left: 22px;
}

.form-labels label textarea {
  width: 40%;
  padding-left: 22px;
}

.form-labels label select,
.form-labels label input[type="number"] {
  width: 224px;
}

.form-labels label textarea {
  height: 200px;
}

.form-labels label input.read-only {
  background-color: #DEDEDE;
  border: 1px solid #DEDEDE;
  cursor: default;
  color: #1D1E2C;
}

.form-labels label input.with-search {
  width: calc(40% - 94px);
}

.form-labels label .search-button {
  width: 71px;
  height: 42px;
  margin-left: 23px;
  border: none;
  border-radius: 3px;
  background-image: url('/src/assets/image/common/searchBtn.svg');
  background-repeat: no-repeat;
}

.form-labels label input[type=file] {
  all: unset;
  margin-left: 30px;
}

.form-labels label select {
  color: black;
  appearance: none;
  background-color: white;
  background-image: url("/src/assets/image/common/selectArrow.svg");
  background-repeat: no-repeat;
  background-position-x: 194px;
  background-position-y: center;
  box-shadow: #00000014 1px 2px 2px;
}

.form-labels label input.read-only {
  background-color: #DEDEDE;
  border: 1px solid #DEDEDE;
  cursor: default;
  color: #1D1E2C;
}

.form-labels input[type=checkbox] {
  padding: 0;
  width: 32px;
  height: 32px;
  position: relative;
  top: 10px;
}

.search-bar {
  height: 122px;
  line-height: 122px;
  position: relative;
  margin-top: 26px;
  background-color: white;
  box-shadow: #0000001A 0px 1px 4px;
}

.search-bar label {
  margin-right: 70px;
  position: relative;
}

.search-bar label>* {
  vertical-align: middle;
}

.search-bar label span {
  display: inline-block;
  width: 116px;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.search-bar label img {
  width: 60px;
  height: 46px;
  border: 1px solid #DEDEDE;
  border-right: none;
  object-fit: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin-left: 16px;
}

.search-bar label.focused img {
  border-color: #677CF5;
}

.search-bar label input,
.search-bar label select {
  width: calc((100% - 130px) / 3 - 202px);
  min-width: 170px;
  height: 46px;
  margin-left: 16px;
  padding: 10px;
  padding-left: 22px;
  background-color: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
}

.search-bar label input {
  width: calc((100% - 130px) / 3 - 262px);
  min-width: 110px;
  padding-left: 22px;
  font-family: 'Poppins';
  margin-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

}

.search-bar label.focused input {
  border-color: #677CF5;
}

.search-bar label input:focus-visible {
  outline: none;
}

.search-bar input[type=date] {
  border: 1px solid #DEDEDE;
  background-color: #F9F9F9;
}

.search-bar input[type=date]::-webkit-calendar-picker-indicator {
  z-index: 1;
  position: absolute;
  left: 126px;
  transform: scale(2.5);
  opacity: 0;
}

.search-bar label[for=sdate] img {
  content: url("/src/assets/image/common/inputSdateIcon.svg");
}

.search-bar label[for=edate] img {
  content: url("/src/assets/image/common/inputEdateIcon.svg");
}

.search-bar label select {
  appearance: none;
  background-color: white;
  background-image: url("/src/assets/image/common/selectArrow.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 22px);
  background-position-y: center;
  box-shadow: #00000014 1px 2px 2px;
}

.search-bar button {
  width: 130px;
  height: 122px;
  border: none;
  border-radius: 0px 5px 5px 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("/src/assets/image/common/searchBtn2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 24px;
}

.search-bar label+label span::before,
.search-bar button::before {
  display: inline-block;
  content: "";
  width: 1px;
  height: 98px;
  background-color: rgba(29, 30, 44, 0.1);
  position: absolute;
  left: 0px;
  top: 10px;
}

.tab-nav {
  margin: 10px 0px;
  text-align: center;
}

.tab-nav button {
  width: 130px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(29, 30, 44, 0.5);
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-family: 'Noto Sans KR';
}

.tab-nav button.active {
  color: rgba(73, 94, 222, 1);
  border-bottom: 2px solid #495EDE;
}

.tab-nav button>* {
  vertical-align: middle;
}

.tab-nav button img {
  margin-right: 3.5px;
  position: relative;
  top: 1px;
}

.user-actions {
  margin-top: 24px;
}

table+.user-actions {
  margin-top: 0px;
}

.user-actions button[type=submit] {
  float: right;
}

.user-action {
  display: inline-block;
  width: 71px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  border: none;
}

.user-action+.user-action {
  margin-left: 10px;
}

.user-action.add,
.user-action.go-list {
  border: 1px solid #A259FF;
  background-color: white;
  color: #A259FF;
}

.user-action.add {
  width: unset;
  height: unset;
  color: #677CF5;
  border: none;
  padding: 4px 28px;
  box-shadow: #0000001A 0px 1px 4px;
}

.user-action.add:hover {
  background-color: #DBE1FF;
}

.user-action.add img {
  margin-right: 6px;
  vertical-align: middle;
}

.user-action.go-list {
  width: 159px;
  height: 46px;
  line-height: 46px;
  border: none;
  background-color: #D4D5DD;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 2px;
  color: #1D1E2C;
}

.user-action.go-list::before {
  content: url("/src/assets/image/common/goListIcon.svg");
  margin-right: 6px;
}

.user-action.save,
.user-action.purge {
  width: 159px;
  height: 46px;
  background: linear-gradient(90deg, #663FED, #3D6DEB);
  color: white;
  box-shadow: #0000001A 0px 1px 4px;
}

.user-action.purge {
  width: 176px;
}

.user-action.send-email {
  width: 166px;
  border: 1px solid #0BCF82;
  background-color: #0BCF82;
  color: white;
}

.user-action.go-back {
  width: 159px;
  height: 46px;
  line-height: 46px;
  background-color: #D4D5DD;
  color: #1D1E2C;
  box-shadow: #0000001A 1px 2px 2px;
}

.user-action.go-back::before {
  content: url("/src/assets/image/common/backArrow.svg");
  margin-right: 4px;
  position: relative;
  top: 2px;
}

.user-action.download {
  width: 145px;
  height: 40px;
  border: none;
  background-image: url("/src/assets/image/common/downloadBtn.svg");
  box-shadow: #0000002E 1px 2px 2px;
}

.user-action.button,
.user-action.delete,
.user-action.ct-upload,
.user-action.ct-download,
.user-action.stl-upload,
.user-action.password-change,
.user-action.permission-grant,
.user-action.permission-revoke,
.user-action.savefile-download {
  width: 159px;
  height: 46px;
  line-height: 46px;
  border: none;
  background-color: #D4D5DD;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 2px;
  color: #1D1E2C;
}

.user-action.delete,
.user-action.cancel {
  background-color: #f5838e;
  color: white;
  float: right;
}

.user-action.delete img {
  content: url("/src/assets/image/common/deleteBtnIcon.svg");
  margin-right: 6px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}

.custom-link {
  color: #007FFF;
  cursor: pointer;
}

.break-all {
  word-break: break-all !important;
}

.text-right {
  text-align: right !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.color-red {
  color: #F6354A !important;
}

.chart-wrapper {
  background-color: white;
  padding: 20px;
  box-shadow: #0000001a 0px 1px 4px;
  margin-top: 30px;
}

.font-bold {
  font-weight: bold;
}

/* ================ add-modal ================ */
.add-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-modal .modal {
  background-color: white;
  width: 755px;
  font-weight: bold;
}

.add-modal .modal .close {
  text-align: right;
  padding: 22px 34px 10px;
}

.add-modal .modal .close button {
  border: none;
  width: 15px;
  height: 15px;
  background-image: url("/src/assets/image/common/modalClose.svg");
  background-color: white;
  background-position: center;
}

.add-modal .modal .head {
  padding-left: 50px;
  font-size: 20px;
}

.add-modal .form-labels {
  margin: 0px 50px;
  padding: 10px 10px;
  box-shadow: none;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}

.add-modal .form-labels .red {
  color: #F24E1E;
}

.add-modal .form-labels label {
  margin: 20px 0px;
}

.add-modal .form-labels label>span {
  width: 235px;
  text-align: left;
  padding-left: 10px;
}

.add-modal .form-labels input {
  background-color: #F9F9F9;
  color: #6E6F76;
}

.add-modal .form-labels select {
  width: 224px;
  background-position-x: 190px;
}

.add-modal .form-labels .long {
  width: 362px;
}

.add-modal input#file {
  color: #6E6F76;
}

.add-modal .bottom {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #EEEFF6;
  padding-right: 30px;
  margin-top: 20px;
}

.add-modal .bottom button {
  height: 47px;
  padding: 0px 40px;
  border: none;
  color: #8B909F;
  font-size: 18px;
  font-weight: bold;
}

.add-modal .bottom button[type=submit] {
  width: 150px;
  color: #DBDBDB;
  background: linear-gradient(90deg, #663FED, #3D6DEB);
  box-shadow: #00000029 0px 3px 6px;
}

.add-modal .bottom button[type=submit]:hover {
  background: linear-gradient(90deg, #4D30B2, #2C4EAA);
}

.add-modal input#hospital {
  width: 304px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.add-modal label[for=hospital] button {
  width: 58px;
  margin-left: 0px;
  background-color: white;
  border: 1px solid #DEDEDE;
  background-image: url("/src/assets/image/common/searchIcon.svg");
  background-position: center;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  line-height: 42px;
  position: relative;
  bottom: 0.7px;
}

/* ================ confirm-modal ================ */
.confirm-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #363640;
  opacity: 0.5;
  z-index: 9999;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
}

.confirm-modal .modal-contents {
  width: 587px;
  height: fit-content;
  background-color: white;
  text-align: center;
}

.confirm-modal .modal-contents .close button {
  background-image: url("/src/assets/image/common/modalClose.svg");
  border: none;
  width: 15px;
  height: 15px;
  background-color: white;
  background-position: center;
  margin: 23px 29px;
}

.confirm-modal .modal-contents .close img {
  margin: 23px 29px 27px;
}

.confirm-modal .modal-contents h1 {
  font-size: 20px;
  text-align: left;
  padding: 0px;
  margin-left: 50px;
  border: none;
}

.confirm-modal .modal-contents label {
  display: block;
  text-align: left;
  margin: 27px 50px 80px;
}

.confirm-modal .modal-contents label span {
  font-size: 16px;
  font-weight: bold;
  color: #F24E1E;
}

.confirm-modal .modal-contents .buttons {
  background-color: #EEEFF6;
  height: 79px;
  line-height: 79px;
  padding-right: 20px;
}

.confirm-modal .modal-contents .buttons button {
  width: 149px;
  height: 47px;
  font-size: 18px;
  font-weight: bold;
}

.confirm-modal .modal-contents .buttons .close-btn {
  border: none;
  color: #8B909F;
}

.confirm-action {
  width: 92px;
  height: 46px;
  font-weight: bold;
  margin: 3px;
  border: none;
  font-size: 16px;
  box-shadow: #0000001A 0px 2px 4px;
}

.confirm-action.delete {
  background-color: #f5838e;
  color: white;
}

.confirm-action.disabled {
  border: 1px solid #C3CBDB;
  background: unset;
  background-color: #C3CBDB;
  color: #8B909F;
  cursor: default;
}

.confirm-action.disabled::before {
  display: none;
}

.confirm-action.disabled:hover {
  background: unset;
  background-color: #C3CBDB;
}

/* ================ /components/ProgressBar ================ */
.progress-bar {
  width: 100vw;
  height: 10px;
  position: absolute;
}

.progress-line {
  height: 100%;
  background-color: greenyellow;
  animation: in-progress 1s infinite;
}

@keyframes in-progress {
  0% {
    width: 50%;
    margin-left: 50%;
  }

  100% {
    width: 1%;
    margin-left: 0%;
  }
}

/* ================ /components/Header ================ */
.common-header {
  height: 73px;
  font-weight: 600;
  border: 1px solid #DEDEDE;
  box-shadow: #00000008 0px 3px 6px;
}

.common-header .menu-wrapper {
  display: inline-block;
  position: relative;
  height: 73px;
  line-height: 73px;
  padding: 0px 29px;
  font-size: 20px;
}

.common-header .menu-wrapper.logo {
  padding: 0px 96px;
  margin-right: 47px;
  font-size: 22px;
  cursor: default;
}

.common-header .menu-wrapper .menu-name {
  cursor: default;
  color: rgba(29, 30, 44, 0.2);
}

.common-header .menu-wrapper .menu-name.active,
.common-header .menu-wrapper:hover .menu-name {
  color: #495EDE;
}

.common-header .menu-wrapper .menu-name.active::after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background-color: #495EDE;
  margin: auto;
  position: relative;
  bottom: 20px;
}

.common-header .menu-wrapper .menu-name.home {
  cursor: pointer;
}

.common-header .menu-wrapper .submenu-wrapper {
  display: none;
  position: absolute;
  top: 71px;
  left: 5px;
  text-align: left;
  background-color: white;
  border: 1px solid #677CF5;
  box-shadow: #00000029 0px 3px 6px;
  z-index: 9999;
}

.common-header .menu-wrapper:hover .submenu-wrapper {
  display: block;
}

.common-header .menu-wrapper .submenu {
  display: block;
  width: 245px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  color: #475271;
  margin: 13px 11px;
  padding-left: 10px;
  letter-spacing: -0.64px;
}

.common-header .menu-wrapper .submenu.active {
  color: #495EDE;
}

.common-header .menu-wrapper .submenu:hover {
  background-color: rgba(73, 94, 222, 0.1);
  border-radius: 3px;
}

.common-header button {
  all: unset;
  display: block;
  float: right;
  margin-top: 13px;
  margin-right: 98px;
  padding: 12px 42px;
  background-color: #9A9DBF;
  color: white;
}

.common-header button:hover {
  background-color: #8285A0;
}

/* ================ /components/Pagination ================ */
.pagination {
  margin-top: 42px;
  text-align: center;
}

.pagination>* {
  display: inline-block;
  width: 38px;
  height: 38px;
  text-align: center;
  vertical-align: middle;
  margin: 0px 10px;
  font-weight: bold;
}

.pagination button {
  border: none;
}

.pagination button.show-first {
  background-image: url('/src/assets/image/components/pagination/showFirst.svg');
}

.pagination button.show-prev {
  background-image: url('/src/assets/image/components/pagination/showPrev.svg');
}

.pagination button.show-prev.disabled {
  background-image: url('/src/assets/image/components/pagination/showPrevDisabled.svg');
}

.pagination button.show-next {
  background-image: url('/src/assets/image/components/pagination/showNext.svg');
}

.pagination button.show-next.disabled {
  background-image: url('/src/assets/image/components/pagination/showNextDisabled.svg');
}

.pagination button.show-last {
  background-image: url('/src/assets/image/components/pagination/showLast.svg');
}

.pagination a {
  line-height: 38px;
}

.pagination a.active,
.pagination .page.active {
  background: linear-gradient(90deg, #663FED, #3D6DEB);
  border-radius: 3px;
  color: white;
}

.pagination .page {
  background-color: white;
}

/* ================ /components/UserModal ================ */
.user-modal-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.3;
}

.user-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-modal .modal-contents {
  width: 689px;
  height: 535px;
  max-width: 1280px;
  background-color: white;
}

.user-modal .modal-contents>* {
  margin: 40px;
  margin-bottom: 0px;
}

.user-modal .modal-contents .modal-title {
  margin-top: 0px;
  font-size: 20px;
  font-weight: bold;
}

.user-modal .modal-contents .modal-header {
  margin: 0px;
  height: 34px;
  padding: 22px 30px 10px;
  font-size: 20px;
  color: white;
  position: relative;
}

.user-modal .modal-contents .modal-header button {
  background-image: url("/src/assets/image/common/modalClose.svg");
  border: none;
  width: 15px;
  height: 15px;
  background-color: white;
  background-position: center;
  position: absolute;
  top: 26px;
  right: 26px;
}

.user-modal .modal-contents form {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.user-modal .modal-contents form input {
  width: 304px;
  height: 42px;
  background-color: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-right: none;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px;
  font-family: 'Noto Sans KR';
  margin-left: 180px;
  font-size: 16px;
  font-weight: bold;
  color: #6E6F76;
}

.user-modal .modal-contents form button {
  width: 58px;
  height: 42px;
  line-height: 42px;
  margin-left: 0px;
  background-color: white;
  border: 1px solid #DEDEDE;
  background-image: url("/src/assets/image/common/searchIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  position: relative;
  bottom: 2px;
}

.user-modal .modal-contents>table {
  width: calc(100% - 80px);
}

.user-modal .modal-contents .table-wrapper {
  margin-top: 0px;
  max-height: 296px;
  overflow: auto;
}

.user-modal .modal-contents .table-wrapper.long {
  border-bottom: 1px solid #C3CBDB;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.user-modal .modal-contents .table-wrapper.long tr:last-child td {
  border-bottom: none;
}

.user-modal .modal-contents .table-wrapper table {
  margin-top: 0px;
}

.user-modal .modal-contents .table-wrapper table tr td {
  background-color: #EEEFF6;
  font-weight: bold;
  height: 50px;
  color: #6E6F76;
}

.user-modal .modal-contents .table-wrapper table tr td.selected {
  color: #007FFF;
}

.user-modal .bottom {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #EEEFF6;
  padding-right: 30px;
  margin-top: 20px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.user-modal .bottom button {
  width: 150px;
  height: 47px;
  padding: 0px 20px;
  border: none;
  color: #8B909F;
  font-size: 18px;
  font-weight: bold;
}

.user-modal .bottom button[type=submit] {
  width: 150px;
  color: #DBDBDB;
  background: linear-gradient(90deg, #663FED, #3D6DEB);
  box-shadow: #00000029 0px 3px 6px;
}

.user-modal .bottom button[type=submit]:hover {
  background: linear-gradient(90deg, #4D30B2, #2C4EAA);
}

/* ================ /components/HospitalModal ================ */
.hospital-modal-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.3;
}

.hospital-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hospital-modal .modal-contents {
  width: 689px;
  height: 535px;
  max-width: 1280px;
  background-color: white;
}

.hospital-modal .modal-contents>* {
  margin: 40px;
  margin-bottom: 0px;
}

.hospital-modal .modal-contents .modal-title {
  margin-top: 0px;
  font-size: 20px;
  font-weight: bold;
}

.hospital-modal .modal-contents .modal-header {
  margin: 0px;
  height: 34px;
  padding: 22px 30px 10px;
  font-size: 20px;
  color: white;
  position: relative;
}

.hospital-modal .modal-contents .modal-header button {
  background-image: url("/src/assets/image/common/modalClose.svg");
  border: none;
  width: 15px;
  height: 15px;
  background-color: white;
  background-position: center;
  position: absolute;
  top: 26px;
  right: 26px;
}

.hospital-modal .modal-contents form {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.hospital-modal .modal-contents form input {
  width: 304px;
  height: 42px;
  background-color: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-right: none;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px;
  font-family: 'Noto Sans KR';
  margin-left: 180px;
  font-size: 16px;
  font-weight: bold;
  color: #6E6F76;
}

.hospital-modal .modal-contents form button {
  width: 58px;
  height: 42px;
  line-height: 42px;
  margin-left: 0px;
  background-color: white;
  border: 1px solid #DEDEDE;
  background-image: url("/src/assets/image/common/searchIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  position: relative;
  bottom: 2px;
}

.hospital-modal .modal-contents>table {
  width: calc(100% - 80px);
}

.hospital-modal .modal-contents .table-wrapper {
  margin-top: 0px;
  max-height: 360px;
  overflow: auto;
}

.hospital-modal .modal-contents .table-wrapper.long {
  border-bottom: 1px solid #C3CBDB;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.hospital-modal .modal-contents .table-wrapper.long tr:last-child td {
  border-bottom: none;
}

.hospital-modal .modal-contents .table-wrapper table {
  margin-top: 0px;
}

.hospital-modal .modal-contents .table-wrapper table tr td {
  background-color: #EEEFF6;
  font-weight: bold;
}

/* ================ /components/SavefileDownloadModal ================ */
.savefile-download-modal-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.3;
}

.savefile-download-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.savefile-download-modal .modal-contents {
  width: 1024px;
  height: 535px;
  max-width: 1280px;
  background-color: white;
}

.savefile-download-modal .modal-contents>* {
  margin: 40px;
  margin-bottom: 0px;
}

.savefile-download-modal .modal-contents .modal-title {
  margin-top: 0px;
  font-size: 20px;
  font-weight: bold;
}

.savefile-download-modal .modal-contents .modal-header {
  margin: 0px;
  height: 34px;
  padding: 22px 30px 10px;
  font-size: 20px;
  color: white;
  position: relative;
}

.savefile-download-modal .modal-contents .modal-header button {
  background-image: url("/src/assets/image/common/modalClose.svg");
  border: none;
  width: 15px;
  height: 15px;
  background-color: white;
  background-position: center;
  position: absolute;
  top: 26px;
  right: 26px;
}

.savefile-download-modal .modal-contents form {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.savefile-download-modal .modal-contents form input {
  width: 304px;
  height: 42px;
  background-color: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-right: none;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px;
  font-family: 'Noto Sans KR';
  margin-left: 180px;
  font-size: 16px;
  font-weight: bold;
  color: #6E6F76;
}

.savefile-download-modal .modal-contents form button {
  width: 58px;
  height: 42px;
  line-height: 42px;
  margin-left: 0px;
  background-color: white;
  border: 1px solid #DEDEDE;
  background-image: url("/src/assets/image/common/searchIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  position: relative;
  bottom: 2px;
}

.savefile-download-modal .modal-contents>table {
  width: calc(100% - 80px);
}

.savefile-download-modal .modal-contents .table-wrapper {
  margin-top: 0px;
  max-height: 360px;
  overflow: auto;
}

.savefile-download-modal .modal-contents .table-wrapper.long {
  border-bottom: 1px solid #C3CBDB;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.savefile-download-modal .modal-contents .table-wrapper.long tr:last-child td {
  border-bottom: none;
}

.savefile-download-modal .modal-contents .table-wrapper table {
  margin-top: 0px;
}

.savefile-download-modal .modal-contents .table-wrapper table tr td {
  background-color: #EEEFF6;
  font-weight: bold;
  height: 50px;
}

/* ================ /components/PermissionRevokeModal ================ */
.permission-revoke-modal-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.3;
}

.permission-revoke-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.permission-revoke-modal .modal-contents {
  width: 689px;
  max-height: 535px;
  max-width: 1280px;
  background-color: white;
}

.permission-revoke-modal .modal-contents>* {
  margin: 40px;
  margin-bottom: 0px;
}

.permission-revoke-modal .modal-contents .modal-title {
  margin-top: 0px;
  font-size: 20px;
  font-weight: bold;
}

.permission-revoke-modal .modal-contents .modal-header {
  margin: 0px;
  height: 34px;
  padding: 22px 30px 10px;
  font-size: 20px;
  color: white;
  position: relative;
}

.permission-revoke-modal .modal-contents .modal-header button {
  background-image: url("/src/assets/image/common/modalClose.svg");
  border: none;
  width: 15px;
  height: 15px;
  background-color: white;
  background-position: center;
  position: absolute;
  top: 26px;
  right: 26px;
}

.permission-revoke-modal .modal-contents .selected-container {
  display: flex;
  flex-flow: wrap;
  margin: 10px 40px;
  gap: 10px;
}

.permission-revoke-modal .modal-contents .selected-container .selected-item {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  padding-right: 15px;
  font-size: 12px;
  border: 1px solid darkgray;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.permission-revoke-modal .modal-contents .selected-container .selected-item::after {
  content: 'X';
  display: block;
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 15px;
  color: darkgray;
}

.permission-revoke-modal .modal-contents>table {
  width: calc(100% - 80px);
}

.permission-revoke-modal .modal-contents .table-wrapper {
  margin-top: 0px;
  max-height: 296px;
  overflow: auto;
}

.permission-revoke-modal .modal-contents .table-wrapper.long {
  border-bottom: 1px solid #C3CBDB;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.permission-revoke-modal .modal-contents .table-wrapper.long tr:last-child td {
  border-bottom: none;
}

.permission-revoke-modal .modal-contents .table-wrapper table {
  margin-top: 0px;
}

.permission-revoke-modal .modal-contents .table-wrapper table tr td {
  background-color: #EEEFF6;
  font-weight: bold;
  height: 50px;
  color: #6E6F76;
}

.permission-revoke-modal .modal-contents .table-wrapper table tr td.selected {
  color: #007FFF;
}

.permission-revoke-modal .bottom {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #EEEFF6;
  padding-right: 30px;
  margin-top: 20px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.permission-revoke-modal .bottom button {
  width: 150px;
  height: 47px;
  padding: 0px 20px;
  border: none;
  color: #8B909F;
  font-size: 18px;
  font-weight: bold;
}

.permission-revoke-modal .bottom button[type=submit] {
  width: 150px;
  color: #DBDBDB;
  background: linear-gradient(90deg, #663FED, #3D6DEB);
  box-shadow: #00000029 0px 3px 6px;
}

.permission-revoke-modal .bottom button[type=submit]:hover {
  background: linear-gradient(90deg, #4D30B2, #2C4EAA);
}

/* ================ /pages/Login ================ */
main.login-page {
  padding: 0px;
}

.login-page .login-back {
  display: flex;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-image: url('/src/assets/image/pages/login/loginBackground.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.login-page .login-form {
  width: 480px;
  text-align: center;
  margin-right: calc(25% - 240px);
}

.login-page .login-form h1 {
  margin-bottom: 32px;
  text-align: center;
  color: #2C3958;

  font-size: 2em;
  padding: 0px;
  border-bottom: none;
}

.login-page .login-form label,
.login-page .login-form label span {
  display: block;
  width: 100%;
  text-align: left;
  color: #2C3958;
}

.login-page .login-form label input {
  display: inline-block;
  width: 100%;
  height: 56px;
  margin-top: 6px;
  margin-bottom: 32px;
  padding: 17px 16px;
  background-color: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  caret-color: #677CF5;
}

.login-page .login-form label input:focus {
  border-color: #677CF5;
}

.login-page .login-form label input:focus-visible {
  outline: none;
}

.login-page .login-form label input.with-button {
  width: calc(100% - 138px);
  border-radius: 4px 0px 0px 4px;
}

.login-page .login-form label button {
  width: 138px;
  height: 56px;
  color: #677CF5;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  border: 1px solid #677CF5;
  border-radius: 0px 4px 4px 0px;
  position: relative;
  top: 2.5px;
}

.login-page .login-form label button.mac-os {
  top: 0px;
}

.login-page .login-form label input::placeholder {
  font-size: 16px;
  color: #707070;
}

.login-page .login-form label.save-email {
  color: #677CF5;
  font-weight: bold;
}

.login-page .login-form label.save-email input {
  width: 22px;
  height: 22px;
  margin: 0;
  vertical-align: middle;
  background-color: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-radius: 3px;
  color: #707070;
}

.login-page .login-form>button {
  display: inline-block;
  width: 200px;
  height: 56px;
  margin-top: 49px;
  background: linear-gradient(90deg, #663FED, #3D6DEB);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  box-shadow: #00000029 0px 3px 6px;
}

.login-page .login-form>button:hover {
  background: linear-gradient(90deg, #4D30B2, #2C4EAA);
}

/* ================ /pages/Home ================ */
.home-page {
  width: 100%;
  height: calc(100vh - 73px);
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-page .content img {
  content: url("/src/assets/image/pages/home/homeIcon.png");
  max-width: 600px;
  position: relative;
  animation: home-page-animation 2s ease-in-out infinite;
}

@keyframes home-page-animation {
  0% {
    top: 0px;
  }

  50% {
    top: 5px;
  }

  100% {
    top: 0px;
  }
}

.home-page .content .text {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 26px;
  font-weight: bold;
  color: #9a9dbf;
  margin-bottom: 30px;
}

/* ================ /pages/hospital/HospitalList ================ */
.hospital-list-page .search-bar label[for=hospitalIdInput] img {
  content: url("/src/assets/image/pages/hospital/hospitalIdIcon.svg");
}

.hospital-list-page .search-bar label[for=hospitalNameInput] img {
  content: url("/src/assets/image/pages/hospital/hospitalNameIcon.svg");
}

.hospital-list-page .user-action.add img {
  content: url("/src/assets/image/pages/hospital/hospitalAddIcon.svg");
  position: relative;
  bottom: 2px;
}

/* ================ /pages/hospital/HospitalEdit ================ */
.hospital-edit-page .form-labels label[for=address] input#address {
  width: calc(100% - 400px);
  max-width: 500px;
  min-width: 224px;
}

.hospital-edit-page .user-list-modal-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.3;
}

.hospital-edit-page .user-list-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hospital-edit-page .user-list-modal .modal-contents {
  width: 689px;
  height: 535px;
  max-width: 1280px;
  background-color: white;
}

.hospital-edit-page .user-list-modal .modal-contents>* {
  margin: 40px;
  margin-bottom: 0px;
}

.hospital-edit-page .user-list-modal .modal-contents .modal-title {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.hospital-edit-page .user-list-modal .modal-contents .modal-header {
  margin: 0px;
  height: 34px;
  padding: 22px 30px 10px;
  font-size: 20px;
  color: white;
  position: relative;
}

.hospital-edit-page .user-list-modal .modal-contents .modal-header button {
  background-image: url("/src/assets/image/common/modalClose.svg");
  border: none;
  width: 15px;
  height: 15px;
  background-color: white;
  background-position: center;
  position: absolute;
  top: 26px;
  right: 26px;
}

.hospital-edit-page .user-list-modal .modal-contents form {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.hospital-edit-page .user-list-modal .modal-contents form input {
  width: 304px;
  height: 42px;
  background-color: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-right: none;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px;
  font-family: 'Noto Sans KR';
  margin-left: 180px;
  font-size: 16px;
  font-weight: bold;
  color: #6E6F76;
}

.hospital-edit-page .user-list-modal .modal-contents form button {
  width: 58px;
  height: 42px;
  line-height: 42px;
  margin-left: 0px;
  background-color: white;
  border: 1px solid #DEDEDE;
  background-image: url("/src/assets/image/common/searchIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  position: relative;
  bottom: 2px;
}

.hospital-edit-page .user-list-modal .modal-contents>table {
  width: calc(100% - 80px);
}

.hospital-edit-page .user-list-modal .modal-contents .table-wrapper {
  margin-top: 0px;
  max-height: 425px;
  overflow: auto;
}

.hospital-edit-page .user-list-modal .modal-contents .table-wrapper.long {
  border-bottom: 1px solid #C3CBDB;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.hospital-edit-page .user-list-modal .modal-contents .table-wrapper.long tr:last-child td {
  border-bottom: none;
}

.hospital-edit-page .user-list-modal .modal-contents .table-wrapper table {
  margin-top: 0px;
}

.hospital-edit-page .user-list-modal .modal-contents .table-wrapper table tr td {
  background-color: #EEEFF6;
  font-weight: bold;
}

/* ================ /pages/user/UserList ================ */
.user-list-page .search-bar label[for=userIdInput] img {
  content: url("/src/assets/image/pages/user/userIdIcon.svg");
}

.user-list-page .search-bar label[for=userNameInput] img {
  content: url("/src/assets/image/pages/user/userEmailIcon.svg");
}

.user-list-page .search-bar label[for=nameInput] img {
  content: url("/src/assets/image/pages/user/userNameIcon.svg");
}

.user-list-page .user-action.add img {
  content: url("/src/assets/image/pages/user/userAddIcon.svg");
  position: relative;
  bottom: 2px;
}

.user-list-page .modal label {
  margin: 23px 0px;
}

.user-list-page .modal input#birth {
  width: 224px;
}

.user-list-page .modal select {
  box-shadow: #00000014 1px 2px 2px;
}

/* ================ /pages/user/UserEdit ================ */
.user-edit-page .form-labels label {
  display: inline-block;
  width: 50%;
  min-width: 600px;
  margin: 12px 0px;
}

.user-edit-page .form-labels label input {
  width: calc(100% - 400px);
}

.user-edit-page .form-labels label input[type=checkbox] {
  width: 32px;
}

/* ================ /pages/patient/PatientList ================ */
.patient-list-page .search-bar label[for=patientIdInput] img {
  content: url("/src/assets/image/pages/patient/patientIdIcon.svg");
}

.patient-list-page .search-bar label[for=patientNameInput] img {
  content: url("/src/assets/image/pages/patient/patientNameIcon.svg");
}

.patient-list-page .search-bar label[for=hospitalNameInput] img {
  content: url("/src/assets/image/pages/patient/hospitalNameIcon.svg");
}

.patient-list-page .user-action.add img {
  content: url("/src/assets/image/pages/patient/patientAddIcon.svg");
  position: relative;
  bottom: 2px;
}

.patient-list-page .add-modal label[for=description] span {
  vertical-align: top;
}

.patient-list-page .add-modal label[for=description] textarea {
  width: 350px;
  height: 100px;
}

.patient-list-page .add-modal select#vendor {
  width: 350px;
  background-position-x: 326px;
  padding-left: 12px;
  font-size: 12px;
  letter-spacing: -1px;
}

/* ================ /pages/patient/PatientEdit ================ */
.patient-edit-page .form-labels label {
  display: inline-block;
  width: 50%;
  min-width: 600px;
  margin: 12px 0px;
}

.patient-edit-page .form-labels label input {
  width: calc(100% - 400px);
}

.patient-edit-page .form-labels label input[type=checkbox] {
  width: 32px;
}